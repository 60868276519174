import styled from '@emotion/styled';
import _ from 'lodash';
import React from 'react';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { TBlock } from 'src/interface/section';
import SectionHeader from 'src/components/sections/section-header';
import CardHalf from 'src/components/cards/card-half';
import CardV from 'src/components/cards/card-v';
import SectionSettrade from '../section-event/section-settrade';
import useResponsive from 'src/utils/devices';
import { Media } from 'src/interface/media';
import CardH from 'src/components/cards/card-h';
import WidgetBackline from '../../widget/widget-backlink';
import DynamicAds, { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { SHOW_COMMEMORATE } from 'src/constants';

type Props = {
  ads?: any;
  data?: TBlock;
  eventDataLayer: IDataLayerGlobal;
  settrade?: boolean;
};

const SectionOneCard = ({ ads, data, eventDataLayer, settrade = false }: Props) => {
  if (_.isEmpty(data)) return null;
  const responsive: Media = useResponsive();
  return (
    <SectionWrapper>
      <div className={`grid-sec-one container`} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <div className='block-1-sec-one'>
          <SectionHeader data={data?.title} link={data?.link} />
          <div className='card-list'>
            {_.map(data?.data.slice(0, 1), (element, index) => (
              <div className={`card-item item-${index}`} key={index}>
                {index === 0 && (
                  <CardHalf
                    data={element}
                    showFooter={false}
                    showBlurb={true}
                    showBadge={false}
                    color='#fff'
                    eventDataLayer={{
                      ...eventDataLayer,
                      data: {
                        title: element?.title,
                        index: index + 1,
                        ...eventDataLayer.data
                      }
                    }}
                  />
                )}
              </div>
            ))}
            {responsive.isTabletPro && (
              <>
                {_.map(data?.data.slice(1, 4), (element, index) => (
                  <div className={`card-item item-${index + 1}`} key={index}>
                    <>
                      <CardV
                        data={element}
                        showFooter={true}
                        showBlurb={false}
                        showBadge={false}
                        showPublishDate={true}
                        eventDataLayer={{
                          ...eventDataLayer,
                          data: {
                            title: element?.title,
                            index: index + 2,
                            ...eventDataLayer.data
                          }
                        }}
                      />
                    </>
                  </div>
                ))}
              </>
            )}
            {responsive.isTabletMini && (
              <>
                {_.map(data?.data.slice(1, 4), (element, index) => (
                  <div className={`card-item item-${index + 1}`} key={index}>
                    <>
                      <CardH
                        data={element}
                        showFooter={true}
                        showBlurb={false}
                        showBadge={false}
                        showPublishDate={true}
                        eventDataLayer={{
                          ...eventDataLayer,
                          data: {
                            title: element?.title,
                            index: index + 2,
                            ...eventDataLayer.data
                          }
                        }}
                      />
                    </>
                  </div>
                ))}
              </>
            )}
          </div>

          <WidgetBackline link={data?.link} />
        </div>
        <div className='block-2-sec-one'>
          {settrade ? <SectionSettrade /> : <>
            <div>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.STICKY_1} data={ads} />}</div>
            <div>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.STICKY_2} data={ads} />}</div>
          </>}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionOneCard;

const SectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .seo-index-h2 {
    position: absolute;
    opacity: 0;
  }
  .grid-sec-one {
    display: flex;
    gap: 10px;
    @media (max-width: 960px) {
      flex-direction: column;
    }
    .block-1-sec-one {
      width: 70%;
      @media (max-width: 960px) {
        width: 100%;
      }
      .card-list {
        margin: 10px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        grid-template-areas: 'item-0 item-0 item-0' 'item-1 item-2 item-3';
        @media (max-width: 768px) {
          grid-template-areas:
            'item-0 item-0 item-0'
            'item-1 item-1 item-1'
            'item-2 item-2 item-2'
            'item-3 item-3 item-3';
        }
        .item-0 {
          grid-area: item-0;
        }
        .item-1 {
          grid-area: item-1;
        }
        .item-2 {
          grid-area: item-2;
        }
        .item-3 {
          grid-area: item-3;
        }
      }
    }
    .block-2-sec-one {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 960px) {
        width: 100%;
      }

      .section-settrade {
        @media (max-width: 690px) {
          display: none;
        }
      }
    }
  }
`;
