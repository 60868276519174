import styled from '@emotion/styled';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { Media } from 'src/interface/media';

import { PSection, TBlock } from 'src/interface/section';
import _ from 'lodash';
import CardFull from 'src/components/cards/card-full';
import Carousels from 'src/components/carousels';
import useResponsive from 'src/utils/devices';
import { controller } from 'src/switch-themes';
import SectionHeader from '../section-header';
import SectionOneCard from '../section-card/section-one';
import { ITags } from 'src/interface/service';
import SectionTags from 'src/components/sections/section-tags';
import { SHOW_COMMEMORATE } from 'src/constants';
// import CardV from 'src/components/cards/card-v';
// import WidgetBackline from '../../widget/widget-backlink';

type Props = {
  ads?: any;
  highlight1: PSection['highlight1'];
  dataPopular: any;
  headerLatestNews: TBlock;
  eventDataLayer: IDataLayerGlobal;
  dataTags?: { data: ITags[] };
};

const { description } = controller;

const SectionOne = ({ ads, highlight1, dataPopular, headerLatestNews, dataTags, eventDataLayer }: Props) => {
  const responsive: Media = useResponsive();
  return (
    <>
      <SectionWrapper id='section-1' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <h2 className='seo-index-h2'>{description}</h2>

        <div className={`grid-sec-one container desktop`} >
          <div className='block-1 --top'>
            <Carousels dots={true} mobileSlideShow={1} slideShow={1}>
              {_.map(highlight1?.data.slice(0, 5), (element, index) => (
                <CardFull
                  key={index}
                  data={element}
                  showFooter={false}
                  showBlurb={false}
                  showBadge={false}
                  // ! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      block: '1',
                      heading: 'highlight',
                      title: element?.title,
                      carousel: index + 1
                    }
                  }}
                />
              ))}
            </Carousels>
          </div>
          {responsive.isTabletPro && (
            <div className='block-2'>
              {!_.isEmpty(dataPopular) && (
                <>
                  <SectionHeader data={dataPopular?.title} link={dataPopular?.link} fontColor={'#000'} />

                  <div className='card-list'>
                    {_.map(dataPopular?.data?.slice(0, 3), (element, index) => (
                      <div className='card-item' key={index}>
                        <CardFull
                          data={element}
                          showFooter={false}
                          showBlurb={false}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            data: {
                              block: '2',
                              heading: 'ข่าวยอดนิยม',
                              title: element?.title,
                              index: index + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          {/* {responsive.isTabletMini && (
            <div className='block-2'>
              {!_.isEmpty(dataPopular) && (
                <>
                  <SectionHeader data={dataPopular?.title} link={dataPopular?.link} fontColor={'#000'} />
                  <div className='card-list'>
                    {_.map(dataPopular?.data?.slice(0, 3), (element, index) => (
                      <div className='card-item' key={index}>
                        <CardV
                          data={element}
                          showFooter={true}
                          showBlurb={false}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            data: {
                              block: '2',
                              heading: 'ข่าวยอดนิยม',
                              title: element?.title,
                              index: index + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <WidgetBackline link={dataPopular?.link} />
                </>
              )}
            </div>
          )} */}
        </div>
      </SectionWrapper>
      <SectionWrapper id='section-2'>
        <SectionOneCard
          ads={ads}
          data={headerLatestNews}
          // ! DATALAYER
          eventDataLayer={{
            ...eventDataLayer,
            section: 'section-2',
            position: 'ใต้:section-1',
            data: {
              block: '1',
              heading: 'ข่าวล่าสุด'
            }
          }}
        />
      </SectionWrapper>
      <SectionTagWrapper>
        <div className='container'>
          <SectionTags
            tags={dataTags}
            title='แท็กยอดนิยม'
            color='#194588'
            tagsIndex='index'
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'แท็กยอดนิยม',
              position: 'ใต้:section-2',
              data: {
                heading: 'แท็กยอดนิยม',
                ...eventDataLayer?.data
              }
            }}
          />
        </div>
      </SectionTagWrapper>
    </>
  );
};

export default SectionOne;

const SectionTagWrapper = styled.div`
  background: #f4f4f4;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding: 30px 0;
  .heading-click-on-section {
    display: none;
    @media (max-width: 690px) {
      display: block;
    }
  }
  .heading-click {
    @media (max-width: 690px) {
      display: none;
    }
  }
  .seo-index-h2 {
    position: absolute;
    opacity: 0;
  }
  .grid-sec-one {
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .block-1 {
      width: 75%;
      @media (max-width: 768px) {
        width: 100%;
      }
      &.--top {
        margin-top: 75px;
        .title {
          font-size: 34px; 
          @media (max-width: 690px) {
            font-size: 18px; 
          }
        }
        @media (max-width: 690px) {
          margin-top: 0px;

        }
      }
    }
    .block-2 {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .card-list {
        /* gap: 10px; */

        margin: -5px -5px;
        .card-item {
          padding: 5px 5px;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        .card-list {
          display: flex;
          flex-wrap: wrap;
          .card-item {
            padding: 5px 5px;
            width: 50%;
          }
        }
      }
    }
  }
`;
