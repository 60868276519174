// import WidgetSettrade from 'src/components/widgets/widget-settrade';
import styled from '@emotion/styled';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import React from 'react';

const WidgetGoldStat = dynamic(import('src/components/widget/widget-gold-stat'));
const WidgetOilStat = dynamic(import('src/components/widget/widget-oil-stat'));

const SectionSettrade: NextPage = () => {
  return (
    <>
      <SectionWrapper>
        <div className='section-settrade'>
          <div className='block-1'>
            <WidgetOilStat />
          </div>
          <div className='block-2'>
            <WidgetGoldStat />
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.div`
  .section-settrade {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .block-1,
    .block-2 {
      max-width: 100%;
    }
  }
`;

export default SectionSettrade;
